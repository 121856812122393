import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image";
import Header from "./header"
import Seo from "./seo"

import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import {
  GriddedH1,
  GriddedH2,
  GriddedH3,
  GriddedP,
  GriddedHR,
  GriddedImg,
  GriddedBlockquote,
  GriddedSpan,
  GriddedMeta,
  GriddedInlineCode,
  Video,
  Gallery,
  ArticleGrid,
} from "./block-styles"

import SketchWrapper from "./SketchWrapper";

import { Link } from "gatsby"

const components = {
  h2: GriddedH2,
  h3: GriddedH3,
  p: GriddedP,
  hr: GriddedHR,
  span: GriddedSpan,
  img: GriddedImg,
  div: GriddedInlineCode, // for maths blocks - TODO: make this more selective 
  blockquote: GriddedBlockquote,
  Link,
  Gallery,
  Video,
  SketchWrapper
}

export default function PageTemplate({ data: { mdx } }) {
  // console.log(mdx.body)

  return (
    <div>
      <Seo />
      <Header />
      <MDXProvider components={components}>
        <ArticleGrid>
          <GriddedH1>{mdx.frontmatter.title}</GriddedH1>
          <GriddedMeta>{mdx.frontmatter.date}</GriddedMeta>
          {/* {mdx.frontmatter.featuredImage && (
            <FeaturedImageBlock>
              <FeaturedImage
                fluid={mdx.frontmatter.featuredImage.childImageSharp.gatsbyImageData}
              />
            </FeaturedImageBlock>
          )} */}
          <MDXRenderer>{mdx.body}</MDXRenderer>
        </ArticleGrid>
      </MDXProvider>
    </div>
  );
}

export const pageQuery = graphql`query BlogPostQuery($id: String) {
  mdx(id: {eq: $id}) {
    id
    body
    frontmatter {
      title
      date(formatString: "MMMM DD, YYYY")
      # featuredImage {
      #   childImageSharp {
      #     gatsbyImageData(
      #       quality: 80
      #       transformOptions: {cropFocus: CENTER}
      #       layout: FULL_WIDTH
      #     )
      #   }
      # }
    }
  }
}
`

const FeaturedImageBlock = styled.div`
  display: grid;
  grid-template-columns: var(--gridTemplateColumnsDefault);
  column-gap: var(--gridColumnGap);

  grid-template-areas: "a a a a a a";

  grid-column-start: 1;
  grid-column-end: -1;

  @media screen and (min-width: 768px) {
    grid-template-areas: ". a a a a a a a a a a .";
  }

  @media screen and (min-width: 1280px) {
    grid-template-areas: ". . a a a a a a a a . .";
  }
`
const FeaturedImage = styled(GatsbyImage)`
  grid-area: a / a / a / a;

  margin: var(--gridMarginGap) 0;
`
